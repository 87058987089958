require("./assets/styles/_main.scss");
require("!style-loader!css-loader!video.js/dist/video-js.css");
require("!style-loader!css-loader!owl.carousel2/dist/assets/owl.carousel.css");
require("!style-loader!css-loader!owl.carousel2/dist/assets/owl.theme.default.css");

import $ from "jquery";
import jquery from "jquery";
import Video from "video.js";
import "owl.carousel2";
import "bootstrap/js/dist/util";
import "bootstrap/js/dist/modal";
import "bootstrap-dropdown-hover/dist/jquery.bootstrap-dropdown-hover";
import "./assets/js/goTop";
import "./assets/js/dropdown";
import "./assets/js/sideNav";
import "./assets/js/header";
import "./assets/js/hiding_section";
import "./assets/js/search_filter";

window.videojs = Video;

jquery(() => {
  console.log("jQuery, popper, video.js, bootstrap 4, owl.carousel");

  function load_next_thumbnails(card_selector, card_list_selector) {
    var nextPageUrl = $(".pagination .next a").attr("href");
    if (!!nextPageUrl) {
      var date = new Date();
      var timestamp = date.getTime();
      nextPageUrl += "&ajax_load=" + timestamp;
      $(".pagination").remove();
      $("#spinner-bottom").show();
      $.get(nextPageUrl).then(
        function (html) {
          var new_cards = $(html).find(card_selector);
          new_cards.appendTo($(card_list_selector));
          var new_pagination = $(html).find(".pagination").hide();
          new_pagination.appendTo($("#content"));
          $("#spinner-bottom").hide();
        },
        function () {
          $("#spinner-bottom").hide();
        }
      );
    } else {
      $(window).off("scroll");
    }
  }

  $(
    ".template-big_thumbnails, .template-small_thumbnails, .template-twofold_view"
  ).each(function () {
    var card_selector, card_list_selector, scroll_throttle_timeout;
    if ($(this).hasClass("template-small_thumbnails")) {
      card_selector = "#thumbnails .card";
      card_list_selector = "#thumbnails .card-list";
      scroll_throttle_timeout = 300;
    }
    if ($(this).hasClass("template-big_thumbnails")) {
      card_selector = "#thumbnails .image-block";
      card_list_selector = "#thumbnails .entry-list";
      scroll_throttle_timeout = 300;
    }
    if ($(this).hasClass("template-twofold_view")) {
      card_selector = "#content-twofold .folder";
      card_list_selector = "#content-twofold";
      scroll_throttle_timeout = 2500;
    }

    $(".pagination").hide();

    var scrollbar_is_visible = function () {
      return $(document).height() > $(window).height();
    };

    var has_more_content_to_show = function () {
      return $(".pagination .next a").length > 0;
    };

    var load_next_thumbnails_interval = setInterval(function () {
      if (!scrollbar_is_visible() && has_more_content_to_show()) {
        load_next_thumbnails(card_selector, card_list_selector);
      } else {
        clearInterval(load_next_thumbnails_interval);
      }
    }, 2000);

    // Check every 300ms the scroll position
    $(document).on(
      "scroll",
      _.throttle(function () {
        load_next_thumbnails(card_selector, card_list_selector);
      }, scroll_throttle_timeout)
    );
  });

  $(".video-player").each(function () {
    var player = videojs(this, {
      controlBar: {
        pictureInPictureToggle: true,
        remainingTimeDisplay: false,
      },
      resizeManager: false,
    });

    // disable right click menu
    player.on('contextmenu', function(e) {
        e.preventDefault();
    });
  });

  $(".flow-player").each(function () {

    var root = $(this);

    var update_validation_elements = function(img_element) {

      var img = $(img_element);
      var portal_type = img.attr("data-portal-type");
      var review_state = img.attr("data-review-state");
      var url = img.attr("data-url");

      if (portal_type === "Video" && review_state === "pending") {
        $(".validation-video").removeClass("hide");
      } else {
        $(".validation-video").addClass("hide");
      }

      $("#form-video-state").attr("action", url + "/content_status_modify");

      $('#video-validated,#video-rejected').removeAttr("checked");

      var state_circle_container = $("#video-validation-circle-status");
      var state_circle = state_circle_container.find(".circle-state");
      state_circle
        .removeClass("pending")
        .removeClass("validated")
        .removeClass("rejected")
        .removeClass("private")
        .removeClass("published")
        .addClass(review_state);

      if (review_state === "validated") {
        state_circle_container.removeClass("hide");
        state_circle.html("Validated");
      } else if (review_state === "rejected") {
        state_circle_container.removeClass("hide");
        state_circle.html("Rejected");
      } else if (review_state === "pending" && portal_type === "Video") {
        state_circle_container.removeClass("hide");
        state_circle.html("On hold");
      } else {
        state_circle_container.addClass("hide");
        state_circle.html("");
      }
    };

    $('.video-thumbnail img', root).click(function(event) {
      event.preventDefault();
      event.stopPropagation();

      var player_element = $(".video-player", root);
      var player = videojs(player_element.get(0));
      var url = $(this).attr('data-url');
      var title = $(this).attr('title');
      var description = $(this).attr('data-description');
      var uid = $(this).attr('data-uid');
      var can_download_video = ($(this).attr('data-can-download') === 'True');
      player.src({type: 'video/mp4', src: url + '/@@stream'});
      player.poster(url + '/@@images/image/poster');
      root.find('#video-aside h2.title-video').text(title);
      root.find('#video-aside div.description-video').text(description);

      // store current video uid; needed later to update thumbnail data
      $("#video-aside").attr("data-current-video-uid", uid);

      // video validation form & buttons refresh
      update_validation_elements(this);

      // video download button refresh
      var vdc = $("#video-download-container");
      if (can_download_video) {
        vdc.removeClass("hide");
        vdc.find(".download-button").attr("href", url + '/@@download/file');
      } else {
        vdc.addClass("hide");
        vdc.find(".download-button").attr("href", "#");
      }

    });

    // checked "validate" or "reject"
    $('#form-video-state .custom-checkbox-square').change(function (evt) {

      var workflow_action = evt.target.value;  // e.g. validate
      var expected_state = evt.target.name;  // e.g. validated
      var auth_token = $("#form-video-state input[name='_authenticator']").val();  // needed for CRSF protection

      $.post(
        $("#form-video-state").attr("action"),
        {"workflow_action": workflow_action, "_authenticator": auth_token}
      ).then(function (html) {

        // store fresh CSRF token
        var new_token = $(html).find("input[name='_authenticator']").val();
        $("#form-video-state input[name='_authenticator']").val(new_token);

        // get corresponding thumbnail, update stored review state, update visuals
        var uid = $("#video-aside").attr("data-current-video-uid");
        var img = $(".video-thumbnail img[data-uid='" + uid + "']").get(0);
        $(img).attr("data-review-state", expected_state);
        update_validation_elements(img);
      });

    });

      var owl = $('.owl-carousel', root);
      owl.owlCarousel({
        items: 4,
        loop:false,
        margin:0,
        stagePadding: 30,
        nav:true,
        center: false,
        responsive:{
          0:{
            items:1,
            nav:true,
            dots: false,
          },
          600:{
            items:3,
            nav:true,
            dots: false,
          },
          1000:{
            items:4,
            nav:true,
            dots: false,
          }
        }
      });

      function resizeItem(){
        $('.owl-item', root).each(function() {
          var width = $(this).prop('style').width;
          $(this).css('min-width', width);
        });
      }

      owl.on('resized.owl.carousel', function(event) {
        resizeItem();
      });

      resizeItem();

  });

  // Dropdown menus are displayed on hover, but their top link is still clickable
  $.fn.bootstrapDropdownHover({ clickBehavior: "link" });

  // Remove value from input button Search
  $(".template-facetednavigation_view .faceted-area .searchButton").each(
    function () {
      $(this).val("");
      $(this).siblings("input[type=text]").css("width", "calc(100% - 34px)");
    }
  );
});
